import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./routes/Home";
import Products from "./routes/Products";
import ProductPage from "./routes/ProductPage";
import ContactPage from "./routes/ContactPage";
import CheckoutPage from "./routes/CheckoutPage";
import Footer from "./components/Footer";
import FacebookChat from "./components/FacebookChat";
import ThankYouPage from "./routes/ThankYouPage";
import ReactGA from "react-ga";
import BlackFriday from "./components/homeComponents/BlackFriday";

function App() {
  ReactGA.initialize("G-WL1SLYXH7J ");
  return (
    <div className="App">
      <BlackFriday />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produktet" element={<Products />} />
        <Route path="/produktet/:name" element={<ProductPage />} />
        <Route path="/kontakti" element={<ContactPage />} />
        <Route path="/produktet/checkout/:name" element={<CheckoutPage />} />
        <Route path="/thank-you-page" element={<ThankYouPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
