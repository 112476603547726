import p1 from "../assets/productsPageAssets/acneProduct.png";
import p2 from "../assets/productsPageAssets/21dbs.png";
import p3 from "../assets/productsPageAssets/21hair.png";
import p4 from "../assets/productsPageAssets/laresi.png";
import p5 from "../assets/productsPageAssets/serumiEuk.png";
import p6 from "../assets/productsPageAssets/sapuni.png";
import p7 from "../assets/productsPageAssets/set5x.jpg";
import p8 from "../assets/productsPageAssets/seti3xEuk.png";
import p9 from "../assets/productsPageAssets/set2x.jpg";
import p10 from "../assets/productsPageAssets/seti2x.png";
import p11 from "../assets/productsPageAssets/seti3x.png";
import p12 from "../assets/productsPageAssets/seti5x.png";
import p13 from "../assets/productsPageAssets/antiRrudhe.png";
import p14 from "../assets/productsPageAssets/kremidheserumi.png";
// import p15 from "../assets/productsPageAssets/setiFloke2.png";
import p16 from "../assets/productsPageAssets/seti3xNia.png";
import p17 from "../assets/productsPageAssets/kremiSapuni.png";
import p18 from "../assets/productsPageAssets/serumiNia.png";
import p19 from "../assets/productsPageAssets/kremiLaresi.png";

const productsData = [
  {
    id: 1,
    name: "Kremi për akne",
    // discountPrice: (19.9).toFixed(2),
    discountPrice: (19.9).toFixed(2),
    price: (30.9).toFixed(2),
    image: p1,
    description:
      "Lëkurë të pastër brena tre javësh? Absolutisht! '21 Ditë pa Akne' Formula jonë argëtuese dhe efektive largon aknet, duke ju dhënë lëkurë të pastër dhe me shkëlqim në vetëm tre javë. Aknet nuk kanë asnjë shans!",
  },
  {
    id: 2,
    name: "Çaji 21 Ditë dobësohu",
    discountPrice: (29.9).toFixed(2),
    price: (79.9).toFixed(2),
    image: p2,
    description:
      "Hiqni kilogramët e tepërt pa mundim me '21 Ditë Dobësohu!' Çaji ynë i shijshëm e bën të lehtë dobësimin. Shijoni një filxhan dhe shikoni magjinë të ndodhë!",
  },
  {
    id: 3,
    name: "Seti 21 Ditë me flokë",
    discountPrice: (29.9).toFixed(2),
    price: (49.9).toFixed(2),
    image: p3,
    description:
      "Rigjallëroni rrënjët tuaja me '21 ditë me flokë!' Shikoni flokët tuaj të rriten më gjatë dhe më të fortë në vetëm tre javë, duke e kthyer çdo ditë në një ditë të mirë të flokëve.",
  },
  // {
  //   id: 15,
  //   name: "Seti 21 Ditë me flokë + Masazhues",
  //   discountPrice: (29.9).toFixed(2),
  //   price: (49.9).toFixed(2),
  //   image: p15,
  //   description:
  //     "Flokë të reja për 21 ditë. Rigjallëroni rrënjët tuaja me '21 ditë me flokë!' Shikoni flokët tuaj të rriten më gjatë dhe më të fortë në vetëm tre javë, duke e kthyer çdo ditë në një ditë të mirë të flokëve.",
  // },
  {
    id: 10,
    name: "Set 2X (Krem + Serum Euk)",
    discountPrice: (34.9).toFixed(2),
    price: (44.9).toFixed(2),
    image: p10,
    description:
      "Thuaju lamtumirë akneve të bezdisshme me Kombinimin tonë Krem + Serum Euk! Kjo dyshe dinamike është arma juaj e re sekrete për lëkurë të pastër dhe me shkëlqim.",
  },
  {
    id: 11,
    name: "Seti 3X (Krem + 2 Seruma)",
    discountPrice: (34.9).toFixed(2),
    price: (54.9).toFixed(2),
    image: p11,
    description:
      "Double serume, double effect! Kombinimi Krem + 2 seruma është treshja juaj më e mirë për lëkurë me shkëlqim, të hidratuar dhe pa të meta.",
  },
  {
    id: 13,
    name: "Seti Anti Rrudhe Retinol serum + Vitamin C krem",
    discountPrice: (29.9).toFixed(2),
    price: (49.9).toFixed(2),
    image: p13,
  },
  {
    id: 14,
    name: "Seti 2x - Krem + Serum me niacinamide dhe zink",
    discountPrice: (29.9).toFixed(2),
    price: (44.9).toFixed(2),
    image: p14,
  },
  {
    id: 12,
    name: "Seti 5x me maskë Falas",
    discountPrice: (44.9).toFixed(2),
    price: (74.9).toFixed(2),
    image: p12,
    description:
      "Jeni lodhur nga aknet? 'Set 5x' ofron 5 produkte të jashtëzakonshme plus një maskë falas për të arritur lëkurën tuaj më të mirë.",
  },
  // {
  //   id: 7,
  //   name: "Seti 5x plus Maska FALAS (krem, larës, serum, sapun)",
  //   discountPrice: (44.9).toFixed(2),
  //   price: (74.9).toFixed(2),
  //   image: p7,
  //   description:
  //     "Arritni lëkurë perfekte me setin 4X! Ky set thelbësor përmban një krem, pastrues, serum dhe sapun, duke ofruar një qasje gjithëpërfshirëse për lëkurën e bukur dhe rrezatuese.",
  // },
  // {
  //   id: 9,
  //   name: "Seti 2x (Krem dhe serum)",
  //   discountPrice: (34.9).toFixed(2),
  //   price: (44.9).toFixed(2),
  //   image: p9,
  //   description:
  //     "Shkëlqeni pa mundim me 'Seti 2X!' Kjo palë e përsosur - një krem ​​dhe serum - ofron hidratim dhe rigjallërim të thellë, duke e lënë lëkurën tuaj të lëmuar, të gjallë dhe të bukur.",
  // },
  {
    id: 16,
    name: "Seti 3x (Krem, serumi me Niacinamide, larës)",
    discountPrice: (34.9).toFixed(2),
    price: (64.9).toFixed(2),
    image: p16,
    description:
      "Zbuloni lëkurën me shkëlqim me Setin 3X Kjo treshe perfekte përfshin një krem, pastrues dhe serum, duke ofruar gjithçka që ju nevojitet për një çehre rrezatuese dhe të shëndetshme.",
  },
  {
    id: 8,
    name: "Seti 3x (Krem, serumi EUK, larës)",
    discountPrice: (34.9).toFixed(2),
    price: (64.9).toFixed(2),
    image: p8,
    description:
      "Zbuloni lëkurën me shkëlqim me Setin 3X Kjo treshe perfekte përfshin një krem, pastrues dhe serum, duke ofruar gjithçka që ju nevojitet për një çehre rrezatuese dhe të shëndetshme.",
  },
  {
    id: 4,
    name: "Larësi me brushë personale silikoni",
    // discountPrice: (29.90).toFixed(2),
    price: (19.9).toFixed(2),
    image: p4,
    description: "description",
  },
  {
    id: 17,
    name: "Seti 2x (Kremi + Sapuni pastrues)",
    // discountPrice: (29.90).toFixed(2),
    price: (29.9).toFixed(2),
    image: p17,
    description: "description",
  },
  {
    id: 19,
    name: "Seti 2x (Kremi + Larësi me brushë personale)",
    discountPrice: (34.9).toFixed(2),
    price: (44.9).toFixed(2),
    image: p19,
    description: "description",
  },
  {
    id: 5,
    name: "Serumi EUK 134 për njolla",
    discountPrice: (19.9).toFixed(2),
    price: (29.9).toFixed(2),
    image: p5,
    description:
      "Luftoni njollat me 'Serumi EUK 124 për njolla' Kjo përzierje e fuqishme hidraton dhe pastron lëkurën, duke e lënë lëkurën tuaj të lëmuar dhe pa njolla. Njollat nuk kanë asnjë shans!",
  },
  {
    id: 18,
    name: "Serum me niacinamide dhe zink",
    discountPrice: (19.9).toFixed(2),
    price: (29.9).toFixed(2),
    image: p18,
    description:
      "Luftoni aknet me Serumin me niacinamide dhe zink. Kjo përzierje e fuqishme hidraton dhe pastron lëkurën, duke e lënë lëkurën tuaj të lëmuar dhe pa njolla. Aknet nuk kanë asnjë shans!",
  },
  {
    id: 6,
    name: "Sapun Pastrues",
    discountPrice: (9.9).toFixed(2),
    price: (19.9).toFixed(2),
    image: p6,
    description: "description",
  },
];

export default productsData;
