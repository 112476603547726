import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import bannerImg from "../../assets/homeAssets/bannerImg.png";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";

const Banner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="left">
        <motion.h3
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.6 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
        >
          21 Ditë Produkt
        </motion.h3>
        <motion.h1
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.6 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
        >
          Produkte organike <br /> pa efekte anësore
        </motion.h1>
        <motion.p
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.6 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.7, type: "spring" }}
        >
          Çdo produkt është i testuar nga laboratorët adekuat.
        </motion.p>
        <Link to={"/produktet"}>
          <motion.button
            variants={{
              hidden: { opacity: 0, scale: 0.6 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
          >
            SHIKO ME SHUMë
          </motion.button>
        </Link>
      </div>
      {/* <motion.img
        variants={{
          hidden: { opacity: 0, scale: 0.6 },
          visible: { opacity: 1, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 1.2, type: "spring" }}
        className="bannerImg"
        src={bannerImg}
        alt=""
      /> */}
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7% 4em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .bgElement {
    width: 100%;
    z-index: 0;
    position: absolute;
    opacity: 0.1;
  }
  .left {
    z-index: 1;
    text-align: center;
    h3 {
      font-weight: 500;
      color: darkgray;
      margin-left: 5%;
    }
    h1 {
      z-index: 1;
      font-size: 6vw;
      font-weight: 200;
      font-family: "The Seasons";
      color: #278527c1;
    }
    p {
      color: gray;
      margin-left: 5%;
    }
    button {
      margin-top: 1em;
      padding: 15px 25px;
      border: unset;
      border-radius: 15px;
      color: #4a913c;
      z-index: 1;
      background: #f1f1f1;
      position: relative;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 19px;
      -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
      box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
      transition: all 250ms;
      overflow: hidden;
      cursor: pointer;
      text-transform: uppercase;
    }

    button::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      border-radius: 15px;
      background-color: var(--primaryColor);
      z-index: -1;
      -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
      box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
      transition: all 250ms;
    }

    button:hover {
      color: #e8e8e8;
    }

    button:hover::before {
      width: 100%;
    }
  }
  .bannerImg {
    width: 45%;
    z-index: 0;
  }
  @media (max-width: 630px) {
    flex-direction: column;
    text-align: center;
    gap: 2em;
    .left {
      h1 {
        font-size: 13vw;
      }
    }
    .bannerImg {
      width: 80%;
    }
  }
`;

export default Banner;
