import React, { useEffect } from "react";
import styled from "styled-components";
import productsData from "../../components/productsData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import img1 from "../../assets/productsPageAssets/swiperImg1.jpg";
import img2 from "../../assets/productsPageAssets/swiperImg2.jpg";
import img3 from "../../assets/productsPageAssets/swiperImg3.jpg";
import { Link } from "react-router-dom";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Produktet | 21 Ditë Produkt";
  }, []);
  return (
    <Container>
      {/* <Swiper
        className="swiper"
        pagination={true}
        modules={[Pagination, Autoplay]}
        slidesPerView={"auto"}
        spaceBetween={4}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        speed={1000}
      >
        <SwiperSlide className="slide">
          <div className="left">
            <h1>Produkte efektive për akne</h1>
          </div>
          <img src={img1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide2">
          <div className="left">
            <h1>Produkte efektive për flokë</h1>
          </div>
          <img src={img2} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide3">
          <div className="left">
            <h1>Produkte efektive për dobësim</h1>
          </div>
          <img src={img3} alt="" />
        </SwiperSlide>
      </Swiper> */}
      <div className="products">
        {productsData.map((item) => (
          <div className="item">
            <Link to={`/produktet/${item.name}`}>
              <img src={item.image} alt="" />
            </Link>
            <div className="details">
              <h3>{item.name}</h3>
              {item.discountPrice ? (
                <span>
                  <h3>€{item.price}</h3>
                  <h2>€{item.discountPrice}</h2>
                </span>
              ) : (
                <h2>€{item.price}</h2>
              )}
              <Link to={`/produktet/${item.name}`}>
                <button>BLEJ TANI</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 0;
  width: 100%;
  .swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .slide {
      padding: 5% 10%;
      width: 85%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      gap: 8%;
      background: #dff5dfc1;
      .left {
        width: 56%;
        h1 {
          color: #464646;
          font-size: 5vw;
          font-family: "The Seasons", sans-serif;
          font-weight: 300;
        }
      }
      img {
        height: 100%;
        max-width: 80%;
        object-fit: contain;
      }
    }
  }
  .products {
    padding: 7em 7%;
    display: flex;
    justify-content: center;
    gap: 4em 2em;
    flex-wrap: wrap;
    .item {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      img {
        margin: auto;
        height: 200px;
        width: 100%;
        object-fit: contain;
        cursor: pointer;
        transition: ease-in-out 0.4s;
        &:hover {
          transform: scale(1.05);
        }
      }
      .details {
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        text-align: end;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: end;
        padding: 10px 1em 0 1em;
        h3 {
          color: #292929c1;
          font-weight: 400;
          font-size: 18px;
          text-transform: capitalize;
        }
        span {
          display: flex;
          align-items: center;
          gap: 7px;
          h3 {
            color: gray;
            font-size: 16px;
            text-decoration: line-through;
          }
        }
        h2 {
          color: #4b4643;
          font-weight: 500;
          font-size: 18px;
        }
        button {
          font-family: "Montserrat", sans-serif;
          color: #383838;
          padding: 10px 30px;
          background: none;
          border: 1px solid #aaaaaac1;
          cursor: pointer;
          transition: 0.4s;
          &:hover {
            background: #278527c1;
            color: white;
          }
        }
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 5%;
    height: 5px;
    background-color: #dff5dfc1;
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #a8eca8c1;
    opacity: 1;
  }
  @media (max-width: 600px) {
    .swiper {
      height: auto;
      .slide {
        height: 80vh;
        gap: 3%;
        flex-direction: column;
        .left {
          width: 100%;
          text-align: center;
          h1 {
            font-size: 8vw;
          }
        }
        img {
          height: auto;
          max-height: 60%;
          max-width: 100%;
        }
      }
    }
  }
  @media (max-width: 450px) {
    padding: 4em 0;
    .swiper {
      .slide {
        width: 100%;
        height: 60vh;
        .left {
          h1 {
            font-size: 10vw;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .products {
      .item {
        width: 45%;
        height: auto;
        img {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }
  @media (max-width: 510px) {
    .products {
      gap: 2em 0px;
      .item {
        width: 48%;
        gap: 10px;
        img {
          height: auto;
          width: 100%;
        }
        .details {
          border-left: none;
          border-right: none;
          h3 {
            font-size: 14px;
          }
          button {
            padding: 10px 20px;
          }
        }
      }
    }
  }
`;

export default Products;
